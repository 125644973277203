import { useEffect, useMemo, useRef } from 'react';
import { useAtomValue } from 'jotai';
import { useRouter } from 'next/router';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';

import { geographySearchAtom } from 'src/components/ReactGeographySearch/atoms';

import { searchInfoAtom } from 'src/entities/listing/atoms/searchInfoAtom';

import { formSearchParamsAtom } from 'src/hooks/useRealEstateLiteSearch';
import { useListingSearchParams } from './useListingSearchParams';

import type { GeographySearch } from 'src/libs/geography';

import { useTracking } from 'src/tracking/hooks/useTracking';

import { deepEqual } from 'src/utils/object';

const getComparableGeography = (geography: GeographySearch | null) => {
  switch (geography?.searchType) {
    case 'place':
      return geography.value.map((entity) => `${entity.id}-${entity.type}`);
    case 'circle':
      return [`${geography.value.radius}-${geography.value.center}`];
    case 'polygon':
      return geography.value.points;
    default:
      return null;
  }
};

export const useTrackSearch = () => {
  const geographyData = useAtomicStateAtomValue(geographySearchAtom);
  const searchParams = useListingSearchParams();
  const searchInfo = useAtomValue(searchInfoAtom);
  const { pathname } = useRouter();
  const oldPathname = useRef(pathname);
  const { trackLaunchSearch, trackSearchFilter } = useTracking();
  const isSamePathname = pathname === oldPathname.current;

  const comparableGeography = useMemo(
    () => getComparableGeography(geographyData),
    [geographyData]
  );

  const oldSearchInfoData = useRef(searchInfo);

  // Track geography Updates
  const oldGeographyData = useRef(comparableGeography);

  useEffect(() => {
    const isSameGeography = deepEqual(
      comparableGeography,
      oldGeographyData.current
    );

    if (
      !geographyData ||
      isSameGeography ||
      searchInfo?.isFetching ||
      !searchInfo?.token ||
      searchInfo?.token === oldSearchInfoData.current?.token ||
      !isSamePathname
    ) {
      return;
    }

    oldGeographyData.current = comparableGeography;
    oldPathname.current = pathname;
    oldSearchInfoData.current = searchInfo;

    trackLaunchSearch();
  }, [
    comparableGeography,
    geographyData,
    isSamePathname,
    pathname,
    searchInfo,
    trackLaunchSearch,
  ]);

  const formSearchParams = useAtomicStateAtomValue(formSearchParamsAtom);

  // Track geography Updates
  const oldParamsData = useRef(formSearchParams);

  useEffect(() => {
    const areSameParams = deepEqual(formSearchParams, oldParamsData.current);

    if (
      !searchParams ||
      areSameParams ||
      searchInfo?.isFetching ||
      !searchInfo?.token ||
      searchInfo?.token === oldSearchInfoData.current?.token ||
      !isSamePathname
    ) {
      return;
    }

    oldParamsData.current = formSearchParams;
    oldPathname.current = pathname;
    oldSearchInfoData.current = searchInfo;
    trackSearchFilter();
  }, [
    geographyData,
    searchParams,
    formSearchParams,
    searchInfo,
    isSamePathname,
    pathname,
    trackSearchFilter,
  ]);
};
