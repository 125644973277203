import clsx from 'clsx';

import { PepitaIcon } from 'src/libs/ui/pepita-icon';

import type { Feature } from 'src/types/real-estate';

import { getFeatureIcon } from 'src/utils/getFeatureIcon';

import css from './styles.module.scss';

interface ListingFeaturesProps {
  features: Feature[];
  customClass?: string;
  size?: 'small' | 'medium' | 'big';
}

export function ListingFeatures({
  features,
  customClass,
  size = 'small',
}: ListingFeaturesProps) {
  return (
    <div
      className={clsx(
        css['in-listingFeatures'],
        css[`is-${size}`],
        customClass
      )}
    >
      {features.map((item, i) => (
        <div
          key={i}
          className={css['in-listingFeatures__item']}
          data-type={item.type}
        >
          <PepitaIcon
            {...getFeatureIcon(item.type)}
            customClass={clsx(
              size !== 'big' && 'nd-icon--sm',
              css['in-listingFeatures__icon']
            )}
          />
          <span>{item.compactLabel || item.label}</span>
        </div>
      ))}
    </div>
  );
}
