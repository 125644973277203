import clsx from 'clsx';

import { RealEstateLoweredPrice } from 'src/components/RealEstateLoweredPrice';

import { isFeatureEnabled } from 'src/config/features-toggle';

import { ListingFeatures } from 'src/entities/listing/components';
import { getListingImagePlaceholder } from 'src/entities/listing/utils/images';

import { PepitaFigure } from 'src/libs/ui/pepita-figure';

import type { Feature } from 'src/types/real-estate';
import type { Unit as UnitType } from 'src/types/units';

import css from './styles.module.scss';

export type UnitProps = UnitType & {
  matchSearch?: boolean;
  url?: string;
  featureList: Feature[];
};

export function Unit({ unit }: { unit: UnitProps }) {
  return (
    <>
      <PepitaFigure
        ratio="square"
        customClass={clsx(
          css['in-listingCardUnit__thumb'],
          isFeatureEnabled('GENERIC_IMAGE') && 'in-figure--genericPlaceholder'
        )}
        cover
      >
        <PepitaFigure.Image
          lazyLoad
          src={unit.photo?.urls.small || getListingImagePlaceholder()}
          alt={unit.photo?.caption || 'placeholder'}
        />
      </PepitaFigure>
      <div className={css['in-listingCardUnit__content']}>
        <div className={css['in-listingCardUnit__title']}>
          {unit.typology?.name}
        </div>
        <span className={css['in-listingCardUnit__price']}>
          {unit.price?.formattedValue}
        </span>
        {unit.price?.loweredPrice && (
          <RealEstateLoweredPrice data={unit.price.loweredPrice} isCompact />
        )}
        <ListingFeatures
          customClass={css['in-listingCardUnit__features']}
          features={unit.featureList}
        />
      </div>
    </>
  );
}
