/**
 * Defers the execution of the provided callback until the browser is idle,
 * using `requestIdleCallback` if available, otherwise falling back to `setTimeout`.
 *
 * @param {Function} callback - The function to be executed when the browser is idle.
 */
export const deferCallback = (callback: () => void) => {
  if (typeof requestIdleCallback !== 'undefined') {
    requestIdleCallback(callback);
  } else {
    setTimeout(callback, 0);
  }
};
