import { useTranslations } from '@pepita-react/i18n';

import { PepitaButton } from 'src/libs/ui/pepita-button';

import type { UnitProps } from '../Unit';

export function ShowAll({ units }: { units: UnitProps[] }) {
  const { trans } = useTranslations();
  const matchUnits = units.filter((item) => item.matchSearch === true).length;

  return (
    <>
      <span>
        {matchUnits < units.length
          ? trans('act_typologies_mach', {
              params: [matchUnits, units.length],
            })
          : trans('lbl_count_typologies', {
              params: [units.length],
            })}
      </span>
      <PepitaButton
        variant="link"
        small
        icon={{
          name: 'external-link',
        }}
      >
        {trans('act_see_all_fe', { capitalize: true })}
      </PepitaButton>
    </>
  );
}
