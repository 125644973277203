import clsx from 'clsx';

import { RealEstateLoweredPrice } from 'src/components/RealEstateLoweredPrice';

import { FormattedPrice } from 'src/entities/listing/components';

import { useListingCardContext } from '../context';

import css from './styles.module.scss';

export function Price() {
  const { realEstate, isCompact } = useListingCardContext();

  const {
    price: { minValue, formattedValue, visible, loweredPrice },
    isProjectLike,
    type,
  } = realEstate;

  return (
    <div
      className={clsx(
        css['in-listingCardPrice'],
        isCompact && css['is-small'],
        loweredPrice && css['is-lowered']
      )}
    >
      <FormattedPrice
        listingType={type}
        isProjectLike={isProjectLike}
        formattedValue={formattedValue}
        minValue={minValue}
        isVisible={visible}
        listPipeCustomClass={css['has-ellipsisOnOverflow']}
      />
      {loweredPrice && (
        <RealEstateLoweredPrice
          isProjectLike={isProjectLike}
          data={loweredPrice}
          isCompact
          customClass={css['in-listingCardPrice__loweredPrice']}
        />
      )}
    </div>
  );
}
