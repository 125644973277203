import { useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';
import Head from 'next/head';

import type { ListingPhotosProps } from 'src/entities/listing/components';
import { ListingPhotos } from 'src/entities/listing/components';

import { useTracking } from 'src/tracking/hooks/useTracking';

import { MultimediaTypes } from 'src/types/real-estate';
import type { MultimediaLite } from 'src/types/real-estate-lite';

import {
  addPageFragment,
  PageFragmentPrefix,
  removePageFragmentWithPrefix,
} from 'src/utils/pageFragment';
import { getMapInfoImage } from './utils';

import { useListingCardContext } from '../context';

import { Mosaic } from './Mosaic';

import css from './styles.module.scss';

interface PropertyMediaProps {
  lazyLoad?: boolean | 'only-carousel';
  preload?: boolean;
}

export function PropertyMedia({
  lazyLoad,
  preload = false,
}: PropertyMediaProps) {
  const { realEstate, isCompact, isMosaic } = useListingCardContext();
  const { trackLightListingViewed } = useTracking();
  const { trans } = useTranslations();

  const { id, isNew, status, visibility, properties, disabled } = realEstate;
  const mainProperty = properties[0];
  const photos = mainProperty.multimedia?.photos;
  const photoMainUrl = photos?.[0]?.urls.small;
  const mapInfoImage = getMapInfoImage(trans, mainProperty.location, isCompact);
  const isSinglePhoto = !isMosaic;
  const extraImages = mapInfoImage && {
    photos: [
      {
        customClass: css['in-listingCardPropertyMedia__photo--mapInfo'],
        ...mapInfoImage,
      },
    ],
  };

  const getRatio = () => {
    if (isCompact && !isSinglePhoto) {
      return 'square';
    }

    if (!visibility || visibility === 'premium' || visibility === 'base') {
      return 'custom';
    }

    return 'standard';
  };

  const photoData: ListingPhotosProps = {
    photoMainUrl,
    customClass: clsx(
      css['in-listingCardPropertyMedia__photo'],
      isCompact &&
        isSinglePhoto &&
        css['in-listingCardPropertyMedia__photo--compactSingle']
    ),
    realEstateStatus: status,
    multimedia: mainProperty.multimedia as unknown as MultimediaLite,
    extraMultimedia: extraImages as unknown as MultimediaLite,
    visibility,
    brandNew: isNew,
    overlay: !isCompact && true,
    imageSize: 'small',
    type: MultimediaTypes.Photos,
    lazyLoad,
    disabled,
    ratio: getRatio(),
    hasCustomRatio: isCompact && isSinglePhoto,
    onThirdSlideChangeOnce: () => {
      trackLightListingViewed(id, 'List');
    },
    onSlideChange: ({ activeImage }) => {
      if (activeImage.id) {
        addPageFragment(`${PageFragmentPrefix.ACTIVE_IMAGE}-${activeImage.id}`);
      } else {
        removePageFragmentWithPrefix(PageFragmentPrefix.ACTIVE_IMAGE);
      }
    },
  };

  const preloadPhotoMainUrl =
    preload && photoMainUrl ? (
      <Head>
        <link
          rel="preload"
          href={photoMainUrl}
          as="image"
          fetchpriority="high"
        />
      </Head>
    ) : undefined;

  if (isSinglePhoto) {
    return (
      <div
        className={clsx(
          css['in-listingCardPropertyMedia'],
          disabled && 'is-disabled',
          isCompact && css['is-compact']
        )}
      >
        {preloadPhotoMainUrl}
        <ListingPhotos {...photoData} />
      </div>
    );
  }

  return (
    <div
      className={clsx(
        css['in-listingCardPropertyMedia'],
        disabled && 'is-disabled',
        isCompact && css['is-compact']
      )}
    >
      {preloadPhotoMainUrl}
      <Mosaic
        photoData={photoData}
        secondaryImagesCustomClass={clsx(
          css['in-listingCardPropertyMedia__photo'],
          css['in-listingCardPropertyMedia__photo--secondary']
        )}
        lazyLoad={lazyLoad === true}
        isCompact={isCompact}
      />
    </div>
  );
}
