import { type SyntheticEvent } from 'react';
import listingDetailSprite from '@pepita-fe/sprite-listing-detail/sprite.svg';
import { useTranslations } from '@pepita-react/i18n';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import clsx from 'clsx';

import { PepitaButton } from 'src/libs/ui/pepita-button';
import { PepitaIcon } from 'src/libs/ui/pepita-icon';

import { useListingCardContext } from '../context';

import css from './styles.module.scss';

interface SavedNoteProps {
  value: string;
  onClick: () => void;
}

export function SavedNote({ value, onClick }: SavedNoteProps) {
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });

  const {
    realEstate: { disabled },
  } = useListingCardContext();

  const { trans } = useTranslations();

  return (
    <div
      className={clsx(
        css['in-listingCardSavedNote'],
        disabled && css['is-disabled']
      )}
    >
      <div className={css['in-listingCardSavedNote__text']}>
        <PepitaIcon
          name="write"
          spriteUrl={listingDetailSprite}
          customClass={css['in-listingCardSavedNote__icon']}
        />
        <p className={css['in-listingCardSavedNote__paragraph']}>
          <span className={css['in-listingCardSavedNote__preValue']}>
            {trans('lbl_note', { capitalize: true })}
          </span>
          <span className={css['in-listingCardSavedNote__value']}>{value}</span>
        </p>
      </div>
      <PepitaButton
        iconOnly={maxScreenWidthSm}
        variant="link"
        icon={maxScreenWidthSm ? { name: 'pencil' } : undefined}
        onClick={(e: SyntheticEvent) => {
          e.preventDefault();
          onClick();
        }}
      >
        {trans('act_edit')}
      </PepitaButton>
    </div>
  );
}
