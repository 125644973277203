import type { SyntheticEvent } from 'react';
import { useCallback } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import { useBreakpoint } from '@pepita-react/use-breakpoint';

import { useRealEstateUserHide } from 'src/hooks/useRealEstateUserHide';

import type { PepitaButtonProps } from 'src/libs/ui/pepita-button';
import { PepitaButton } from 'src/libs/ui/pepita-button';
import { PepitaTooltip } from 'src/libs/ui/pepita-tooltip';

import { deferCallback } from 'src/utils/performance';

import {
  REAL_ESTATE_TRACKING_SECTIONS,
  useRealEstateTracking,
} from 'src/views/RealEstate/hooks/useRealEstateTracking';

import { useListingCardContext } from '../../context';

import css from './styles.module.scss';

export function HideButton() {
  const { trans } = useTranslations();
  const [minScreenWidthSm] = useBreakpoint({ from: 'sm' });
  const { realEstate } = useListingCardContext();

  const { id, type, restored } = realEstate;

  const { hide } = useRealEstateUserHide({
    id,
    type,
  });

  const { trackRealEstateListHide } = useRealEstateTracking({
    section: REAL_ESTATE_TRACKING_SECTIONS.LIST,
    realEstateId: id,
  });

  const getButtonHideAriaLabel = (): string => {
    if (restored) {
      return trans('lbl_restored_ad');
    }

    return trans('act_hide_ad');
  };

  const handleHide = useCallback(
    (evt: SyntheticEvent) => {
      evt.preventDefault(); //Blocks navigations on the card
      hide();

      deferCallback(() => {
        trackRealEstateListHide('lista');
      });
    },
    [hide, trackRealEstateListHide]
  );

  const props: PepitaButtonProps = {
    iconOnly: true,
    icon: {
      name: restored ? 'bin-exclamation-mark' : 'bin',
    },
    onClick: handleHide,
    'aria-label': getButtonHideAriaLabel(),
    customClass: 'cy-hide-button',
  };

  if (restored && minScreenWidthSm) {
    return (
      <PepitaTooltip
        label={<PepitaButton {...props} />}
        dataText={trans('hidden_ad_updated_txt', {
          capitalize: true,
        })}
        type="dark"
        customClass={css['in-listingCardHideButton__tooltip']}
        multiRow
      />
    );
  }

  return <PepitaButton {...props} />;
}
