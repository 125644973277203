import { useBreakpoint } from '@pepita-react/use-breakpoint';
import clsx from 'clsx';

import { useListingCardContext } from '../context';

import css from './styles.module.scss';

export function Description() {
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });
  const { isCompact, isMosaic, realEstate } = useListingCardContext();

  const isDescriptionVisible = isMosaic && !maxScreenWidthSm && !isCompact;

  if (isDescriptionVisible) {
    const mainProperty = realEstate.properties[0];
    const description = mainProperty.description;
    const isDescriptionHigh = mainProperty.featureList.length <= 3;
    const isDescriptionHighOnXLCard = mainProperty.featureList.length <= 4;

    if (!description) return;

    return (
      <div
        className={clsx(
          css['in-listingCardDescription'],
          isDescriptionHigh && css['is-high'],
          isDescriptionHighOnXLCard && css['is-highOnXLCard']
        )}
      >
        {description}
      </div>
    );
  }
}
