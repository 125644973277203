import { useMemo, useRef, useState } from 'react';
import { useAuthContext } from '@indomita-react/auth-provider';
import { useTranslations } from '@pepita-react/i18n';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

import { currentPageAtom } from 'src/components/Pagination/atoms';

import { useLoginWall } from 'src/hooks/useLoginWall';

import {
  PepitaSnackbar,
  PepitaSnackbarAction,
  usePepitaSnackbar,
} from 'src/libs/ui/pepita-snackbar';

import type { RealEstate, SearchListingData } from 'src/types/real-estate';

import { http } from 'src/utils/client/http';

import { buildListingSearchQueryKey } from 'src/views/ListingSearch/hooks/useListingSearch';
import { useListingSearchParams } from 'src/views/ListingSearch/hooks/useListingSearchParams';

type RealEstateIdAndType = Pick<RealEstate, 'id' | 'type'>;

function getListingType(data: RealEstateIdAndType) {
  return data.type === 'project' ? 'progetti' : 'annunci';
}

function hideRealEstateListItem(
  data: RealEstateIdAndType | undefined,
  hidden: boolean
) {
  if (!data) return Promise.resolve(null);

  const url = `/api/utente/${getListingType(data)}/nascosti/${data.id}`;

  return hidden ? http.post(url).json() : http.delete(url).json();
}

export const useRealEstateUserHide = (
  data: RealEstateIdAndType | undefined
) => {
  const { trans } = useTranslations();
  const login = useLoginWall();
  const setSnackbar = usePepitaSnackbar();
  const queryClient = useQueryClient();

  const [isHidden, setIsHidden] = useState(false);

  const { user } = useAuthContext();
  const searchParams = useListingSearchParams();
  const currentPage = useAtomValue(currentPageAtom) || 1;

  const realEstateSearchQueryKey = useMemo(
    () => ({
      currentPage: buildListingSearchQueryKey(
        searchParams,
        currentPage,
        Boolean(user)
      ),
      prevPage: buildListingSearchQueryKey(
        searchParams,
        currentPage - 1,
        Boolean(user)
      ),
    }),
    [user, currentPage, searchParams]
  );

  const prevRealEstateSearchData = useRef<SearchListingData | undefined>();

  const mutation = useMutation<null, null, boolean>({
    mutationFn: (hidden) => hideRealEstateListItem(data, hidden),
    onMutate: (hidden) => {
      if (hidden) {
        //if multiple hide actions are performed, all previous queries are cancelled and only the most recent query is fetched
        queryClient.cancelQueries({
          queryKey: realEstateSearchQueryKey.currentPage,
        });

        const currentPageData = queryClient.getQueryData(
          realEstateSearchQueryKey.currentPage
        ) as SearchListingData | undefined;

        //keep current data as fallback in case of optimic hide failure
        prevRealEstateSearchData.current = currentPageData;

        //if hiding last item in page
        if (currentPageData?.results.length === 1) {
          const prevPageData = queryClient.getQueryData(
            realEstateSearchQueryKey.prevPage
          ) as SearchListingData | undefined;

          queryClient.setQueryData(realEstateSearchQueryKey.prevPage, () => {
            //if prev page is not in cache, set the query data to null to allow queryClient to invalidate when needed (onSuccess)
            if (!prevPageData) return null;

            //if prev page is in cache, set the correct count optimistically
            return {
              ...prevPageData,
              count: currentPageData.count - 1,
            };
          });
        }

        //hide the item in list and update the count optimistically
        queryClient.setQueryData(realEstateSearchQueryKey.currentPage, () => {
          if (!currentPageData) return;

          return {
            ...currentPageData,
            results: currentPageData.results.filter(
              (item) => item.realEstate.id !== data?.id
            ),
            count: currentPageData.count - 1,
          };
        });
      } else {
        const currentPageData = queryClient.getQueryData(
          realEstateSearchQueryKey.currentPage
        ) as SearchListingData;

        //since hide cancel can be done only after hide action, I can optimistically set the fallback data (original data before hide) from earlier as the query data
        queryClient.setQueryData(
          realEstateSearchQueryKey.currentPage,
          prevRealEstateSearchData.current
        );

        //keep current data as fallback in case of optimic hide cancel failure
        prevRealEstateSearchData.current = currentPageData;
      }
    },
    onError: () => {
      setSnackbar(
        <PepitaSnackbar variant="error">
          {trans('toast_generic_error_message')}
        </PepitaSnackbar>
      );

      //set fallback data as query data
      queryClient.setQueryData(
        realEstateSearchQueryKey.currentPage,
        prevRealEstateSearchData.current
      );
    },
    onSuccess: (_, hidden) => {
      //fetch again all markers
      queryClient.invalidateQueries({ queryKey: ['markers'], exact: false });
      //remove map card cached data
      queryClient.removeQueries({
        queryKey: ['geohash-list'],
        exact: false,
      });

      //to check if we really need this state
      setIsHidden(hidden);

      //display snackbar to allow user to cancel hide
      hidden &&
        setSnackbar(
          <PepitaSnackbar
            variant="info"
            action={
              <PepitaSnackbarAction
                onClick={() => {
                  setSnackbar(null);
                  mutation.mutate(false);
                }}
              >
                {trans('act_cancel')}
              </PepitaSnackbarAction>
            }
          >
            {trans('hidden_ad', {
              capitalize: true,
            })}
          </PepitaSnackbar>
        );
    },
    onSettled: () => {
      //SUCCESS: invalidate current and prev page query so I can double-check optimistic data update and fetch refilling items
      //ERROR: invalidate current and prev page query so I can double-check the fallback data correctness
      queryClient.invalidateQueries({
        queryKey: realEstateSearchQueryKey.currentPage,
      });
      queryClient.invalidateQueries({
        queryKey: realEstateSearchQueryKey.prevPage,
      });
    },
  });

  return {
    hidden: isHidden,
    hide: (hidden = true) =>
      user
        ? Promise.resolve(mutation.mutate(hidden))
        : login().then(() => mutation.mutate(hidden)),
  };
};
