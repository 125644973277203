import clsx from 'clsx';

import { DropdownInfo, PriceLowered } from './components/PriceData';

import type { LoweredPrice } from 'src/types/real-estate';

import css from './index.module.scss';

interface RealEstateLoweredPriceProps {
  isProjectLike?: boolean;
  data: LoweredPrice;
  isCompact?: boolean;
  customClass?: string;
}

export function RealEstateLoweredPrice({
  isProjectLike,
  data,
  isCompact = false,
  customClass,
}: RealEstateLoweredPriceProps) {
  return (
    <div className={clsx(css[Style.container], customClass)}>
      <PriceLowered
        isProjectLike={Boolean(isProjectLike)}
        originalPrice={data.originalPrice}
        typologiesCount={data.typologiesCount}
        isCompact={isCompact}
      />
      <span className={css[Style.discount]}>(-{data.discountPercentage}%)</span>
      {!isCompact && (
        <DropdownInfo data={data} loweredBy={data.priceDecreasedBy} />
      )}
    </div>
  );
}

enum Style {
  container = 'in-loweredPrice__container',
  discount = 'in-loweredPrice__discount',
}
