import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';

import { getProductConfig } from 'src/config/product';

import { PepitaBadge, type PepitaBadgeProps } from 'src/libs/ui/pepita-badge';

import type { RealEstate } from 'src/types/real-estate';

import css from './index.module.scss';

type VisibilityBadgeProps = Omit<PepitaBadgeProps, 'children'> & {
  tier?: RealEstate['visibility'];
};

export function VisibilityBadge({
  tier,
  customClass,
  ...props
}: VisibilityBadgeProps) {
  const { trans } = useTranslations();

  const visibilitiesConfig = getProductConfig('visibilities');

  return tier ? (
    <PepitaBadge
      customClass={clsx(
        css['in-listingPhotosVisibilityBadge'],
        css[
          `in-listingPhotosVisibilityBadge--${visibilitiesConfig[tier].style}`
        ],
        customClass
      )}
      variant="reversed"
      {...props}
    >
      {
        // i18n-extract-disable-next-line
        trans(visibilitiesConfig[tier].label)
      }
    </PepitaBadge>
  ) : null;
}
