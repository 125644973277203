import { useCallback } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { useRouter } from 'next/router';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';

import { geographyToSearchParams } from 'src/components/GeographySearch/utils';
import { currentPageAtom } from 'src/components/Pagination/atoms';
import { geographySearchAtom } from 'src/components/ReactGeographySearch/atoms';
import { hasMapCustomPositionAtom } from 'src/components/RealEstateSearchMap/atoms/hasMapCustomPositionAtom';
import { searchOnMapAtom } from 'src/components/RealEstateSearchMap/atoms/searchOnMapAtom';
import { formStateAtom } from 'src/components/SearchFilters/atoms';

import { useDebouncedEffect } from 'src/hooks/useDebouncedEffect';
import { useMapQueryParams } from 'src/hooks/useMapQueryParams';
import { useOnDeepEqualAtomChange } from 'src/hooks/useOnDeepEqualAtomChange';
import { WHITELIST_KEYS } from 'src/hooks/useWhitelistParams';
import { useRedirect } from './useRedirect';

import type { Language } from 'src/types/translations';

import { deepEqual, omit, pick } from 'src/utils/object';
import { deserializeFromUrl, serializeIntoUrl } from 'src/utils/querystring';
import { getPathFromUrl } from 'src/utils/querystring';
import { encodeMapQueryParams } from 'src/utils/searchListMapCenterQueryParams';
import { addLocale } from 'src/utils/url';

const SEARCH_LIST_WHITELIST_KEYS = [
  ...WHITELIST_KEYS,
  'id',
  'imm_source',
] as const;

export const useUrlResolver = (isClientSearch: boolean) => {
  const router = useRouter();
  const { asPath, push, replace } = router;
  const { locale, defaultLocale } = router as {
    locale: Language;
    defaultLocale: Language;
  };

  const searchOnMap = useAtomValue(searchOnMapAtom);
  const formState = useAtomicStateAtomValue(formStateAtom);
  const geographySearchData = useAtomicStateAtomValue(geographySearchAtom);
  const hasMapCustomPosition = useAtomValue(hasMapCustomPositionAtom);

  const currentPage = useAtomValue(currentPageAtom) || 1;
  const setCurrentPage = useSetAtom(currentPageAtom);

  const geographyParams = geographyToSearchParams(geographySearchData);

  const mapQueryParams = useMapQueryParams();

  /* Every time the filters change, we want to reset the currentPage.
  Since in the first render cycle we do a lot of state updates to the
  filter state improperly, we need some trick to prevent resetting
  the currentPage on the first render cycle. */

  useOnDeepEqualAtomChange(formState, () => setCurrentPage(1));

  /* Every time the city change, we want to reset the currentPage */
  useOnDeepEqualAtomChange(geographySearchData, () => setCurrentPage(1));

  const { redirectUrl } = useRedirect({
    params: { ...formState, ...geographyParams },
    pag: currentPage,
    enabled: isClientSearch === true,
  });

  const getUrlWithMapQueryParams = useCallback(
    (url: string) => {
      if (!hasMapCustomPosition) {
        return url;
      }

      const { lat, lng, zoom } = mapQueryParams || {};

      if (lat && lng && zoom) {
        return serializeIntoUrl(
          url,
          encodeMapQueryParams(mapQueryParams ?? {})
        );
      }

      return url;
    },
    [mapQueryParams, hasMapCustomPosition]
  );

  const getPaths = useCallback(
    (urlWithMapQueryParams: string) => ({
      location: getPathFromUrl(addLocale(asPath, locale, defaultLocale)),
      params: omit(deserializeFromUrl(asPath), ...SEARCH_LIST_WHITELIST_KEYS),
      redirectLocation: getPathFromUrl(urlWithMapQueryParams),
      redirectParams: deserializeFromUrl(urlWithMapQueryParams),
    }),
    [asPath, defaultLocale, locale]
  );

  interface NavigateOptions {
    to: string;
    method: 'push' | 'replace';
  }

  const navigate = useCallback(({ to, method }: NavigateOptions) => {
    if (method === 'push') {
      push(to, undefined, { shallow: true });
    } else {
      replace(to, undefined, { shallow: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDebouncedEffect(
    () => {
      // If the redirect is undefined, or the searchOnMap mode is enabled,
      // don't change the current url.
      if (!redirectUrl || Boolean(searchOnMap)) return;

      // Add map query params to the redirect url if the layout has the map
      // and if it has been moved. Otherwise, keep the redirect url as it is.
      const redirectWithMapParams = getUrlWithMapQueryParams(redirectUrl);

      // Get the current url location, the current url params, the redirect
      // url location and the redirect url params.
      const { location, params, redirectLocation, redirectParams } = getPaths(
        redirectWithMapParams
      );

      // If the redirect url is the same as the current url, exit without changing
      // the current url.
      if (location === redirectLocation && deepEqual(params, redirectParams)) {
        return;
      }

      // If the redirect url differs from the current url navigate to the redirect url.
      const mapParams = pick(params, 'mapCenter', 'zoom');

      const redirectMapParams = pick(redirectParams, 'mapCenter', 'zoom');

      navigate({
        to: redirectWithMapParams,
        method: deepEqual(mapParams, redirectMapParams) ? 'push' : 'replace',
      });
    },
    [redirectUrl, getPaths, getUrlWithMapQueryParams, navigate, searchOnMap],
    500
  );
};
