import mainFeaturesSprite from '@pepita-fe/sprite-listing-main-features/sprite.svg';
import secondaryFeaturesSprite from '@pepita-fe/sprite-listing-secondary-features/sprite.svg';
import workstationFeaturesSprite from '@pepita-fe/sprite-workstation-features/sprite.svg';

import { FEATURE_LIST_TYPE } from 'src/types/real-estate';

export const getFeatureIcon = (
  type: FEATURE_LIST_TYPE,
  isHighlighted?: boolean
) => {
  switch (type) {
    case FEATURE_LIST_TYPE.WALL:
      return {
        name: 'wall',
        spriteUrl: secondaryFeaturesSprite,
      };
    case FEATURE_LIST_TYPE.INCOME:
      return {
        name: 'euro-circle',
      };
    case FEATURE_LIST_TYPE.ROOMS:
      return {
        name: 'planimetry',
        spriteUrl: mainFeaturesSprite,
      };
    case FEATURE_LIST_TYPE.SALE_DATE:
      return {
        name: isHighlighted ? 'clock' : 'calendar',
      };
    case FEATURE_LIST_TYPE.SURFACE:
      return {
        name: 'size',
        spriteUrl: mainFeaturesSprite,
      };
    case FEATURE_LIST_TYPE.BATHROOMS:
      return {
        name: 'bath',
        spriteUrl: mainFeaturesSprite,
      };
    case FEATURE_LIST_TYPE.FLOOR:
      return {
        name: 'stairs',
        spriteUrl: mainFeaturesSprite,
      };
    case FEATURE_LIST_TYPE.TYPOLOGY_AMOUNT:
      return {
        name: 'home',
      };
    case FEATURE_LIST_TYPE.LUXURY:
      return {
        name: 'diamond',
      };
    case FEATURE_LIST_TYPE.ELEVATOR:
      return {
        name: 'elevator',
        spriteUrl: secondaryFeaturesSprite,
      };
    case FEATURE_LIST_TYPE.BALCONY:
      return {
        name: 'balcony',
        spriteUrl: secondaryFeaturesSprite,
      };
    case FEATURE_LIST_TYPE.TERRACE:
      return {
        name: 'beach-umbrella',
        spriteUrl: secondaryFeaturesSprite,
      };
    case FEATURE_LIST_TYPE.FORNITURE:
      return {
        name: 'couch-lamp',
        spriteUrl: secondaryFeaturesSprite,
      };
    case FEATURE_LIST_TYPE.BASEMENT:
      return {
        name: 'shelf',
        spriteUrl: secondaryFeaturesSprite,
      };
    case FEATURE_LIST_TYPE.WORKSTATION:
      return {
        name: 'person',
      };
    case FEATURE_LIST_TYPE.FIXED_WORKSTATION:
      return {
        name: 'desk',
        spriteUrl: workstationFeaturesSprite,
      };
    case FEATURE_LIST_TYPE.MEETINGS:
      return {
        name: 'whiteboard',
        spriteUrl: workstationFeaturesSprite,
      };

    case FEATURE_LIST_TYPE.MOBILE_WORKSTATION:
      return {
        name: 'office-chair',
        spriteUrl: workstationFeaturesSprite,
      };
    case FEATURE_LIST_TYPE.PRIVATE_OFFICE:
      return {
        name: 'door',
        spriteUrl: workstationFeaturesSprite,
      };
    default:
      return { name: '' };
  }
};
