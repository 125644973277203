import { clsx } from 'clsx';

import type { ListingPhotosProps } from 'src/entities/listing/components';
import { ListingPhotos } from 'src/entities/listing/components';
import { getListingImagePlaceholder } from 'src/entities/listing/utils/images';

import { PepitaFigure } from 'src/libs/ui/pepita-figure';

import css from './styles.module.scss';

interface MosaicProps {
  /**
   * The props that will be forwarded to the <ListingPhotos> component
   */
  photoData: ListingPhotosProps;
  /**
   * The CSS classes to add to secondary images
   */
  secondaryImagesCustomClass: string;
  /**
   * Enable lazy loading on secondary images (with fadeIn)
   */
  lazyLoad: boolean;
  /**
   * The mosaic will be rendered with the compact style
   */
  isCompact: boolean;
}

export function Mosaic({
  photoData,
  secondaryImagesCustomClass,
  lazyLoad,
  isCompact = false,
}: MosaicProps) {
  const photos = photoData.multimedia.photos;

  const visiblePhotos = isCompact ? 3 : 4;

  const placeholderOffset = photos.length || 1;

  return (
    <div
      className={clsx(
        css['in-listingCardPropertyMediaMosaic__container'],
        isCompact && 'is-compact'
      )}
    >
      <ListingPhotos
        {...photoData}
        customClass={clsx(
          css['in-listingCardPropertyMediaMosaic__item'],
          photoData.customClass
        )}
      />
      {photos.slice(1, visiblePhotos).map((photo, i) => {
        return (
          <PepitaFigure
            key={i}
            ratio="custom"
            customClass={clsx(
              css['in-listingCardPropertyMediaMosaic__item'],
              secondaryImagesCustomClass
            )}
          >
            <PepitaFigure.Image
              src={photo.urls.small || getListingImagePlaceholder()}
              alt={photo.urls.small ? photo.caption : 'placeholder'}
              lazyLoad={lazyLoad}
              fadeIn
            />
          </PepitaFigure>
        );
      })}
      {photos.length < visiblePhotos &&
        Array.from(Array(visiblePhotos - placeholderOffset), (_, i) => (
          <PepitaFigure
            key={i}
            ratio="custom"
            customClass={clsx(
              css['in-listingCardPropertyMediaMosaic__item'],
              secondaryImagesCustomClass
            )}
          >
            <PepitaFigure.Image
              src={getListingImagePlaceholder()}
              alt="placeholder"
              lazyLoad={lazyLoad}
              fadeIn
            />
          </PepitaFigure>
        ))}
    </div>
  );
}
