import { type SyntheticEvent } from 'react';
import listingDetailSprite from '@pepita-fe/sprite-listing-detail/sprite.svg';
import { useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';

import { PepitaButton } from 'src/libs/ui/pepita-button';

import css from './styles.module.scss';

interface NoteButtonProps {
  onClick: () => void;
  disabled: boolean;
}

export function NoteButton({ onClick, disabled = false }: NoteButtonProps) {
  const { trans } = useTranslations();

  return (
    <PepitaButton
      customClass={clsx(
        css['in-listingCardNoteButton__button'],
        disabled && css['is-disabled']
      )}
      iconOnly
      icon={{
        name: 'write',
        spriteUrl: listingDetailSprite,
      }}
      onClick={(e: SyntheticEvent) => {
        e.preventDefault();
        onClick();
      }}
      aria-label={trans('act_add_notes')}
    >
      {trans('lbl_note')}
    </PepitaButton>
  );
}
