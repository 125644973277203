import { useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';

import { LISTING_TYPE } from 'src/constants/real-estate';

import { PepitaList, PepitaListItem } from 'src/libs/ui/pepita-list';

import { capitalize, lowerize } from 'src/utils/string';
import { getFormattedPrice } from './utils';

import css from './styles.module.scss';

interface FormattedPriceProps {
  listingType: LISTING_TYPE;
  formattedValue: string | undefined;
  minValue: string | undefined;
  isVisible: boolean;
  isProjectLike?: boolean;
  hasFullRangePrice?: boolean;
  listPipeCustomClass?: string;
}

export function FormattedPrice({
  listingType,
  formattedValue,
  minValue,
  isVisible,
  isProjectLike,
  hasFullRangePrice = false,
  listPipeCustomClass,
}: FormattedPriceProps) {
  const { trans } = useTranslations();

  const formattedPrice = getFormattedPrice(formattedValue);

  if (!formattedPrice) return null;
  if (formattedPrice.length > 1) {
    return (
      <PepitaList
        type="pipe"
        customClass={clsx(
          css['in-formattedPrice--hasPipe'],
          listPipeCustomClass
        )}
      >
        {formattedPrice.map((p) => (
          <PepitaListItem key={p}>{capitalize(p)}</PepitaListItem>
        ))}
      </PepitaList>
    );
  }

  if (
    listingType === LISTING_TYPE.AUCTION ||
    (isProjectLike && minValue && !hasFullRangePrice)
  ) {
    return (
      <span>
        <span className={css['in-formattedPrice__text']}>{`${lowerize(
          trans('price_range_label_from')
        )} `}</span>
        {listingType === LISTING_TYPE.AUCTION ? formattedPrice[0] : minValue}
      </span>
    );
  }

  return (
    <span
      className={
        !isVisible ? css['in-formattedPrice__priceOnRequest'] : undefined
      }
    >
      {capitalize(formattedPrice[0])}
    </span>
  );
}
