import type { PropsWithChildren } from 'react';
import { useIsOnClient } from '@indomita-react/custom-hooks';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import clsx from 'clsx';

import { ContactCallButton } from 'src/components/ContactCallButton';
import { VisitRequest } from 'src/components/VisitRequest';
import { useVisitRequest } from 'src/components/VisitRequest/hooks/useVisitRequest';

import { useContactSource } from 'src/libs/contact';

import type { PhoneTypes } from 'src/types/real-estate';

import {
  REAL_ESTATE_TRACKING_SECTIONS,
  useRealEstateTracking,
} from 'src/views/RealEstate/hooks/useRealEstateTracking';

import { useListingCardContext } from '../context';

import { ContactFormButton } from './ContactFormButton';

import css from './styles.module.scss';

export function Actions({ children }: PropsWithChildren) {
  const { realEstate, isCompact } = useListingCardContext();
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });

  const { agency, supervisor } = realEstate.advertiser;

  const contactSource = useContactSource();

  const { trackRealEstateModalPhoneContact, trackRealEstatePhoneContact } =
    useRealEstateTracking({
      section: REAL_ESTATE_TRACKING_SECTIONS.LIST,
      realEstateId: realEstate.id,
    });

  const onCallDialogOpen = () => {
    trackRealEstateModalPhoneContact({
      source: contactSource.code,
      realEstateId: realEstate.id,
      advertiser: realEstate.advertiser,
      visibility: realEstate.visibility || null,
      price: realEstate.price,
    });
  };

  const { isVisitRequestEnabled } = useVisitRequest(
    agency?.bookableVisit?.isVisitBookable
  );

  const hasVisitButton = isVisitRequestEnabled && !realEstate.status;

  const isOnClient = useIsOnClient();

  return (
    <div className={css['in-listingCardActions']}>
      <div
        className={clsx(
          css['in-listingCardActions__contacts'],
          isCompact && css['is-compact'],
          !isOnClient && 'is-mobileHydrating'
        )}
      >
        {maxScreenWidthSm && Boolean(realEstate.advertiser.hasCallNumbers) && (
          <ContactCallButton
            supervisor={supervisor}
            agency={agency}
            onModalOpen={onCallDialogOpen}
            onPhoneContactClick={(phoneType: PhoneTypes) => {
              trackRealEstatePhoneContact({
                phoneType,
                source: contactSource.code,
                advertiser: realEstate.advertiser,
                visibility: realEstate.visibility || null,
                price: realEstate.price,
              });
            }}
            iconOnly={hasVisitButton}
            variant="default"
          />
        )}
        <ContactFormButton
          realEstate={realEstate}
          isCompact={isCompact}
          agency={agency}
          source={contactSource}
        />
        {hasVisitButton && (
          <VisitRequest
            listing={realEstate}
            source={contactSource.label}
            buttonProps={{
              iconOnly: isCompact,
            }}
            withLandingDialog
          />
        )}
      </div>
      <div className={css['in-listingCardActions__userPref']}>{children}</div>
    </div>
  );
}
