import type { MouseEvent, ReactNode } from 'react';
import { useMemo, useRef, useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import { clsx } from 'clsx';

import { isFeatureEnabled } from 'src/config/features-toggle';

import { getListingImagePlaceholder } from 'src/entities/listing/utils/images';

import { useIntersectionObserver } from 'src/hooks/useIntersectionObserver';

import { PepitaBadge } from 'src/libs/ui/pepita-badge';
import { PepitaButton } from 'src/libs/ui/pepita-button';
import type { PepitaFigureProps } from 'src/libs/ui/pepita-figure';
import { PepitaFigure } from 'src/libs/ui/pepita-figure';
import { PepitaSlideShow } from 'src/libs/ui/pepita-slideshow';

import type { EventName } from 'src/tracking/utils/enums';

import type {
  Multimedia,
  RealEstate,
  RealEstateStatus,
} from 'src/types/real-estate';
import { MultimediaTypes } from 'src/types/real-estate';
import type { MultimediaLite } from 'src/types/real-estate-lite';

import { isMultimediaIframe } from 'src/utils/isMultimediaIframe';

import { VisibilityBadge } from './VisibilityBadge';

import css from './styles.module.scss';

import genericPlaceholder from '/src/images/figure_placeholder.svg';
import placeholder from '/src/images/immo_placeholder.svg';

interface OnSlideChangeParams {
  activeSlide?: number;
  activeImage: any;
}

export type ListingPhotosProps = {
  width?: number;
  height?: number;
  customClass?: string;
  imageCustomClass?: string;
  hasCustomRatio?: boolean;
  ratio?: PepitaFigureProps['ratio'];
  photoMainUrl?: string;
  imageSize?: 'small' | 'medium';
  visibility?: RealEstate['visibility'];
  overlay?: boolean;
  brandNew?: boolean;
  extraMultimedia?: MultimediaLite;
  multimedia: MultimediaLite | Multimedia;
  lazyLoad?: boolean | 'only-carousel';
  onClick?: (slide?: number, eventName?: EventName) => void;
  onSlideChange?: (params: OnSlideChangeParams) => void;
  onThirdSlideChangeOnce?: () => void;
  type: MultimediaTypes;
  hideArrows?: boolean;
  realEstateStatus?: RealEstateStatus;
  disabled?: boolean;
  isListingDetail?: boolean;
  expandButtonFullScreen?: boolean;
};

export function ListingPhotos({
  width,
  height,
  customClass,
  imageCustomClass,
  hasCustomRatio,
  ratio,
  photoMainUrl,
  imageSize = 'medium',
  visibility,
  overlay,
  brandNew,
  extraMultimedia,
  multimedia,
  lazyLoad = false,
  type,
  hideArrows: hideSlideShowArrows,
  onClick,
  onSlideChange,
  onThirdSlideChangeOnce,
  realEstateStatus,
  disabled,
  isListingDetail,
  expandButtonFullScreen,
}: ListingPhotosProps) {
  const images = multimedia[type];
  const extraImages = extraMultimedia?.[type];
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });

  const imagesWithExtras = useMemo(() => {
    const result = [...(images || [])]; // Insert the images

    if (!images?.length && extraImages?.length) {
      // Add a placeholder image only when there are not images and when there are extraImages
      result.push({
        id: null,
        caption: '',
        urls: {
          small: isFeatureEnabled('GENERIC_IMAGE')
            ? genericPlaceholder
            : placeholder,
        },
      });
    }

    // Add the extra images
    result.push(...(extraImages || []));

    return result;
  }, [images, extraImages]);

  const { trans } = useTranslations();
  const ref = useRef<HTMLElement>(null);
  const [photoCount, setPhotoCount] = useState<number>(1);
  const [showCarousel, setShowCarousel] = useState(lazyLoad === false);
  const hasThirdSlideChanged = useRef<boolean>(false);

  const isVirtualOrPlanimetry =
    type === MultimediaTypes.FloorPlans ||
    type === MultimediaTypes.VirtualTours;

  useIntersectionObserver({
    ref,
    onChange: setShowCarousel,
    root: 'document',
    enabled: !showCarousel && imagesWithExtras.length > 1,
    margin: () => 200,
  });

  let content: ReactNode = null;

  const [activeSlide, setActiveSlide] = useState<number>();
  const slideChangeTimeoutRef = useRef<NodeJS.Timeout>();

  const handleOnSlideChange = (newActiveSlide: number) => {
    if (slideChangeTimeoutRef.current) {
      clearTimeout(slideChangeTimeoutRef.current);
    }

    slideChangeTimeoutRef.current = setTimeout(() => {
      setActiveSlide(newActiveSlide);
      const isLastPhoto = newActiveSlide === imagesWithExtras.length - 1;

      onSlideChange &&
        onSlideChange({
          activeImage: imagesWithExtras[newActiveSlide],
          activeSlide: newActiveSlide,
        });

      if (
        newActiveSlide === 2 &&
        onThirdSlideChangeOnce &&
        !hasThirdSlideChanged.current
      ) {
        onThirdSlideChangeOnce();
        hasThirdSlideChanged.current = true;
      }

      /** extra images at the end of the gallery should not be counted */
      if (isLastPhoto && extraImages?.length) {
        setPhotoCount(newActiveSlide);
      } else {
        setPhotoCount(newActiveSlide + 1);
      }
    }, 250);
  };

  if (showCarousel && imagesWithExtras.length > 1) {
    const handleArrowClick = (evt: MouseEvent<HTMLButtonElement>) => {
      // Blocks the click listener on SearchList
      evt.stopPropagation();
    };

    content = (
      <PepitaSlideShow
        lazyLoad
        onPrevClick={handleArrowClick}
        onNextClick={handleArrowClick}
        onSlideChange={handleOnSlideChange}
        noArrows={hideSlideShowArrows}
      >
        {imagesWithExtras.map((item, i) =>
          !isMultimediaIframe(item.interactive, type) ? (
            <PepitaSlideShow.Image
              src={item.urls?.[imageSize]}
              alt={item.caption}
              key={i}
              width={width || 360}
              height={height || 270}
              customClass={item.customClass || null}
            />
          ) : (
            <PepitaSlideShow.Iframe
              title={item.caption ? item.caption : ''}
              src={item.url}
              key={i}
              customClass={css['in-listingPhotos__iframe']}
              slideProps={{ 'data-text': trans('act_open') }}
            />
          )
        )}
      </PepitaSlideShow>
    );
  }

  const isSingleIframe =
    imagesWithExtras.length === 1 &&
    isMultimediaIframe(imagesWithExtras[0].interactive, type);

  if (isSingleIframe) {
    content = (
      <iframe
        width={width || 314}
        height={height || 236}
        loading="lazy"
        src={imagesWithExtras[0].url}
        title={imagesWithExtras[0].caption ?? ''}
        allow="encrypted-media; xr-spatial-tracking;"
        allowFullScreen
        className="nd-ratio__img"
      />
    );
  }

  return (
    <PepitaFigure
      customClass={clsx(
        css['in-listingPhotos'],
        isVirtualOrPlanimetry && css['in-listingPhotos--customBorder'],
        isSingleIframe && css['in-listingPhotos__iframe'],
        customClass
      )}
      ratio={hasCustomRatio ? 'custom' : ratio ?? 'standard'}
      onClick={() => {
        onClick?.(activeSlide);
      }}
      data-text={isSingleIframe ? trans('act_open') : null}
      ref={ref}
    >
      {content ? (
        <PepitaFigure.Content>{content}</PepitaFigure.Content>
      ) : (
        <PepitaFigure.Image
          lazyLoad={lazyLoad === true}
          customClass={clsx(imageCustomClass)}
          src={photoMainUrl || getListingImagePlaceholder()}
          fadeIn
          width={width || 314}
          height={height || 236}
          alt={photoMainUrl ? images[0]?.caption : 'placeholder'}
        />
      )}
      <PepitaFigure.Overlay customClass={css['in-listingPhotos__overlay']}>
        {expandButtonFullScreen && (
          <PepitaButton
            customClass={css['in-listingPhotos__expand']}
            icon={{
              name: 'expand',
            }}
            iconOnly={maxScreenWidthSm}
          >
            {trans('act_map_grow', {
              capitalize: true,
            })}
          </PepitaButton>
        )}
        {overlay &&
        (multimedia.photos?.length || multimedia.virtualTours?.length) ? (
          <div className={css['in-listingPhotos__media']}>
            {multimedia.virtualTours &&
              multimedia.virtualTours.length > 0 &&
              !isListingDetail && (
                <PepitaBadge
                  variant="reversed"
                  onlyIcon
                  customClass={css['in-listingPhotos__mediaTag']}
                  icon={{
                    name: 'tour',
                  }}
                />
              )}
            {multimedia.photos && multimedia.photos.length > 0 && (
              <>
                {isListingDetail && maxScreenWidthSm && (
                  <PepitaBadge
                    icon={{
                      name: 'gallery',
                    }}
                    variant="reversed"
                    customClass={css['in-listingPhotos__mediaTag']}
                  >
                    {trans('act_show_photo', { capitalize: true })}
                  </PepitaBadge>
                )}
                <PepitaBadge
                  variant="reversed"
                  customClass={css['in-listingPhotos__mediaTag']}
                >
                  {photoCount}/{multimedia.photos.length}
                </PepitaBadge>
              </>
            )}
          </div>
        ) : null}
        {disabled && (
          <PepitaBadge
            customClass={clsx(
              css['in-listingPhotos__badge'],
              css['not-available']
            )}
            position="topRight"
          >
            {trans('lbl_not_available')}
          </PepitaBadge>
        )}

        {brandNew && (
          <PepitaBadge
            position="topRight"
            variant="brand"
            customClass={css['in-listingPhotos__badge']}
          >
            {trans('lbl_new')}
          </PepitaBadge>
        )}
        {realEstateStatus && (
          <PepitaBadge
            variant="promotion"
            position="topRight"
            customClass={css['in-listingPhotos__badge']}
          >
            {trans(
              realEstateStatus === 'sold' ? 'lbl_sold' : 'lbl_rented' // i18n-extract-keys ["lbl_sold", "lbl_rented"]
            )}
          </PepitaBadge>
        )}
        {visibility && (
          <VisibilityBadge tier={visibility} position="bottomLeft" />
        )}
      </PepitaFigure.Overlay>
    </PepitaFigure>
  );
}
