import { getProductConfig } from 'src/config/product';

import type { RealEstate } from 'src/types/real-estate';
import type { Language } from 'src/types/translations';

import { alternateAbsoluteURL } from './url';

interface RealEstateUrlParams {
  id: RealEstate['id'];
  lang: Language;
  // Add `?entryPoint=map` on url query to track the right Entry Point on the
  // real estate page `Listing Viewed` MixPanel event.
  addEntryPointMapParam?: boolean;
}

export const getRealEstateUrl = ({
  id,
  lang,
  addEntryPointMapParam,
}: RealEstateUrlParams) => {
  const entrypointMapParam = addEntryPointMapParam ? '?entryPoint=map' : '';
  const url = `${getProductConfig(
    'realEstatePath'
  )}${id}/${entrypointMapParam}`;

  return alternateAbsoluteURL(url, getProductConfig('defaultLocale'), lang);
};

interface RealEstateUnitUrlParams {
  id: RealEstate['id'];
  parentId: RealEstate['id'];
  lang: Language;
}

export const getRealEstateUnitUrl = ({
  id,
  parentId,
  lang,
}: RealEstateUnitUrlParams) => {
  const url = `${getProductConfig('realEstatePath')}${parentId}/${id}/`;

  return alternateAbsoluteURL(url, getProductConfig('defaultLocale'), lang);
};
