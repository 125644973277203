import type {
  RawFeaturesItem,
  RawPropertiesItem,
  RawUrls,
} from 'src/repository/real-estate/raw-data-types';

import type {
  ADVERTISER_CUSTOM_TYPE,
  AGENCY_TYPE,
  CONTRACT,
  LISTING_TYPE,
  LOCATION_MARKER,
  SUPERVISOR_TYPE,
} from 'src/constants/real-estate';

import type { PepitaIconProps } from 'src/libs/ui/pepita-icon';

import type { BreadcrumbData } from 'src/types/breadcrumb';

import type { AgencyListItem } from './agencies-list';
import type { ListDetail } from './real-estate-list';
import type { RelatedLinksProps } from './related-links';
import type { SuggestedSearchData } from './search';
import type { SEOTagsType } from './seo';

export type SearchListingData = {
  count: number;
  totalAds: number;
  currentPage: number;
  maxPages: number;
  results: ListDetail[];
  isResultsLimitReached: boolean;
  breadcrumbs: BreadcrumbData;
  agencies: AgencyListItem[];
  seoData: SEOTagsType;
  relatedSearches: { title: string; data: RelatedLinksProps[] };
  suggestedSearchData: SuggestedSearchData;
  isSearchEditing?: string;
};

export type RealEstateKey = {
  id: number;
  type: LISTING_TYPE;
};

export type RealEstateId = {
  id: number;
};

export type SaveAdTrackingData = {
  currentPage?: number;
  positionList?: number;
  origin?: string;
};

export type Detail = {
  realEstate: RealEstate;
  seo: Seo;
  trovakasa: TrovaKasa;
  descriptionTranslatorToken?: Nullable<string>;
  preview: Preview;
};

export type RealEstate = {
  dataType: 'real-estate';
  id: number;
  type: LISTING_TYPE;
  typology?: Typology;
  uuid?: string;
  contract: CONTRACT;
  contractValue?: string;
  title?: string;
  visibility: Nullable<Visibility>;
  visitPixel?: string;
  createdAt?: number;
  updatedAt?: number;
  mortgage?: Mortgage;
  note?: string;
  advertiser: Advertiser;
  price: Price;
  properties: Property[];
  state: State;
  propertiesCount?: number;
  idGeoHash?: string;
  isNew: boolean;
  saved?: boolean;
  luxury: boolean;
  reference?: Reference;
  hasMainProperty: boolean;
  isProjectLike: boolean;
  hasParent: boolean;
  availableState?: boolean;
};

export type Visibility =
  | 'supervetrina'
  | 'vetrina'
  | 'top'
  | 'premium'
  | 'star'
  | 'base';

export interface CoworkingHour {
  text: string;
  value: string;
}

export enum WorkstationType {
  PRIVATE_OFFICE = 'private-office',
  MEETINGS = 'meetings',
  FIXED_WORKSTATION = 'fixed-workstation',
  MOBILE_WORKSTATION = 'mobile-workstation',
}
export interface Workstation {
  amount: number;
  amountLabel?: string;
  capacityRange?: string;
  canonRange?: string;
  title?: string;
  type: WorkstationType;
}
export interface PropertyPortion {
  reference: null;
  images: Nullable<PortionsPhotoItems[]>;
  texts: string[];
  workstation: Workstation;
  icon: PepitaIconProps;
}

export type Property = {
  id: string;
  auction?: Auction;
  availability?: string;
  bathrooms?: string;
  buildingUsage?: BuildingUsage;
  buildingYear?: Nullable<number>;
  cadastrals?: Cadastral[];
  caption?: string;
  category: Category;
  condition?: Nullable<string>;
  ga4Condition?: string;
  conditionId?: number;
  costs?: Costs;
  description?: string;
  defaultDescription?: string;
  energy?: Energy;
  features?: string[];
  floor?: Floor;
  floors?: string;
  garage?: string;
  ga4Garage?: string;
  industrial?: Industrial;
  internalDivision?: string;
  kitchenStatus?: string;
  location: Location;
  multimedia: Multimedia;
  parking?: Parking;
  photo?: PhotosItem;
  price: Price;
  primaryFeatures: RawFeaturesItem[];
  reference?: Reference;
  roomForRent?: RoomForRent;
  rent?: Rent;
  rooms?: string;
  roomsValue?: string;
  shop?: Shop;
  surface?: string;
  surfaceConstitution?: SurfaceConstitution;
  showSurfaceConstitution: boolean;
  surfaceValue?: string;
  typology: Typology;
  typologyV2: Nullable<Typology>;
  typologyValue: string;
  unitsValue?: string;
  workDates?: string;
  workProgress?: string;
  land: Land;
  adLinks: AdLinks[];
  coworkingHours?: CoworkingHour[];
  portions?: Omit<PropertyPortion, 'icon'>[];
  bedRoomsNumber?: string;
  elevator?: boolean;
  ga4features?: string[];
  typologyGA4Translation?: string;
  lastUpdate?: string;
  income: boolean;
  typologyAmount?: number;
  mainFeatures: Feature[];
  residentialUnits: RawPropertiesItem['residentialUnits'];
  commercialUnits: RawPropertiesItem['commercialUnits'];
};

export type Category = {
  id: number;
  name: string;
};

export type Typology = {
  id: number;
  name: string;
};

export type Reference = {
  label: string;
  code: string;
};

export type State = {
  id: number;
  name: string;
};

export type Price = {
  minValue?: string;
  maxValue?: string;
  value?: number | null;
  formattedValue?: string;
  visible: boolean;
  loweredPrice?: LoweredPrice;
  priceRange?: string;
  pricePerSquareMeter?: string;
};

export type Costs = {
  condominiumExpenses?: string;
  expenses?: string;
  heatingExpenses?: string;
  appliedVat: Nullable<string>;
  agencyCommission: Nullable<string>;
  notaryCommission?: Nullable<string>;
  bankGuarantee?: Nullable<string>;
  commissionSubject: Nullable<string>;
};

export type LoweredPrice = {
  originalPrice: string;
  currentPrice: string;
  discountPercentage: string;
  priceDecreasedBy: string;
  passedDays: number;
  date: string;
  typologiesCount: number;
};

export type BookableVisit = {
  isVisitBookable: boolean;
  virtualVisitEnabled: boolean;
};

export type VisitRequestDayProps = {
  label: string;
  monthName?: string;
  monthDay?: string;
  value: string;
  checked?: boolean;
  active?: boolean;
};

export type VisitRequestTimetableProps = {
  label: string;
  value: string;
  checked?: boolean;
  active?: boolean;
};

export type VisitRequestAvailability = {
  days: VisitRequestDayProps[];
  timetables: VisitRequestTimetableProps[];
};

export type Advertiser = {
  agency?: AgencyLite;
  supervisor?: Supervisor;
  hasCallNumbers?: boolean;
  availability?: VisitRequestAvailability;
};

export type AgencyLite = {
  id: number;
  type: AGENCY_TYPE;
  customType?: ADVERTISER_CUSTOM_TYPE;
  label?: string;
  displayName?: string;
  imageUrls?: AgencyImageUrls;
  agencyUrl?: string;
  phones: AgencyPhone[];
  externalLinks?: SupervisorExternalUrl;
  text?: string;
  track?: string;
  guaranteed: boolean;
  isPaid: boolean;
  bookableVisit?: BookableVisit;
  showAgentPhone?: boolean;
  showOnlyAgentPhone?: boolean;
  showLogo?: boolean;
};

// NOTE: make optional just to make mock data works
export type AgencyImageUrls = {
  small?: Nullable<string>;
  large?: Nullable<string>;
};

export type Supervisor = {
  type: SUPERVISOR_TYPE;
  label?: string;
  displayName?: string;
  imageUrl?: string;
  imageType?: string;
  imageGender?: string;
  phoneUrl?: string;
  phones: AgencyPhone[];
  url?: string;
};

export type PhoneTypes =
  | 'green-phone'
  | 'vTel1'
  | 'vtel'
  | 'cell'
  | 'tel2'
  | 'tel1';

export type AgencyPhone = {
  type: PhoneTypes;
  value?: string;
};

export type SupervisorExternalUrl = {
  url: string;
  title: string;
};

export type Floor = {
  value?: string;
  abbreviation?: Nullable<string>;
  floorOnlyValue?: string;
  ga4FloorValue?: string;
};

export type SurfaceConstitution = {
  totalMainSurface?: string;
  totalCommercialSurface?: string;
  surfaceConstitutionElements?: SurfaceConstitutionElementsItem[];
};

export type SurfaceConstitutionElementsItem = {
  constitution: string;
  constitutionKey: SurfaceConstitutionKey;
  floor?: Floor;
  surface: string;
  percentage: number;
  surfaceType?: string;
  commercialSurface: string;
};

type SurfaceConstitutionKey =
  | 'residence'
  | 'office'
  | 'shop-commercial-space'
  | 'warehouse-storage'
  | 'box-or-garage'
  | 'storage-room'
  | 'loft'
  | 'balcony'
  | 'terrace'
  | 'garden'
  | 'land'
  | 'common-areas'
  | 'other'
  | 'attic'
  | 'parking-space'
  | 'mezzanine'
  | 'exterior';

export type Location = {
  latitude: number;
  longitude: number;
  zoom: number;
  nation?: { id: string; name: string };
  region: string;
  province: string;
  provinceId?: string;
  city: string;
  cityId: number;
  macrozone: string;
  macrozoneId: number | null;
  microzone: string | null;
  locality: string | null;
  address: string;
  streetNumber: string;
  marker: LOCATION_MARKER;
};

export type Cadastral = {
  cadastral?: string;
  cadastralInfo?: string;
  subCadastral?: string;
};

export type Energy = {
  heatingType?: string;
  airConditioning?: string;
  class?: string;
  zeroEnergyBuilding: boolean;
  epi?: number | string;
  epiUm?: string;
  renewableEpi?: number | string;
  renewableEpiUm?: string;
  energyStatus?: string;
  buildingPerformance?: BuildingPerformance;
  GA4Heating?: string;
  thermalInsulation: Nullable<ThermalInsulation>;
  emission: Nullable<EmissionEnergy>;
};

export type ThermalInsulation = {
  notSpecified?: string;
  netEnergyNeeded?: string;
  thermalInsulationClass?: string;
};

export type EmissionEnergy = {
  notSpecified?: string;
  co2Emission?: string;
  co2EmissionClass?: string;
  co2EmissionClassValued?: boolean;
};

export type BuildingPerformance = {
  winter: string;
  summer: string;
};
export type ScopeStatement = {
  url: string;
  dimension: string;
};

export type Industrial = {
  barnHeight: Nullable<string>;
  underBeamHeight: Nullable<string>;
  pillars: boolean;
  bayes: Nullable<string>;
  numberLoadingDock: Nullable<number>;
  numberCranes: Nullable<number>;
  payload: Nullable<number>;
};

export type Parking = {
  parkingTypology: string;
  carCapacity: number;
  motorcycleCapacity: number;
  garageDoorWidth: string;
  electricGarageDoor: boolean;
  electricCarCharge: boolean;
};

export type Shop = {
  wall?: string;
  license?: string;
  gear?: string;
  openingYears?: string;
  revenue?: string;
  ceilingHeight: string;
  showcasesLabel?: string;
};

export type Auction = {
  quotationProperty?: string;
  saleType?: string;
  saleDate?: string;
  saleDateValue?: string;
  highlightSaleDate?: boolean;
  saleState?: AuctionSaleState;
  minimumOffer?: string;
  expertise?: string;
  securityDeposit?: string;
  expenseAccountDeposit?: string;
  minimumRise?: string;
  minimumRiseRace?: string;
  salePlace?: string;
  contactPerson?: string;
  presentationPlace?: string;
  presentationDeadline?: string;
  expensesDeposit?: number;
  notes?: string;
  modalityDeposit: string;
  publishDate: number;
  changeDate: string;
  procedureNumber?: string;
  procedureType?: string;
  procedureRite?: string;
  exemptionCause?: string;
  auctionCourt?: string;
  expenseBookedDebt: string;
  contributionNotDue: string;
  saleAddress?: string;
  lotNumber?: string;
  totalLotEstates: number;
  lotCategory?: AuctionLotCategory;
  pvpLink?: string;
  auctionSubjects: AuctionSubject[];
  auctionRegister: Nullable<AuctionRegister>;
};

export type AuctionSaleState = {
  name: string;
  isAvailable: boolean;
};

export type AuctionRegister = {
  id: number;
  name: string;
};

export type AuctionLotCategory = {
  id: number;
  name: string;
};

export type AuctionSubject = {
  name: string | null;
  surname: string | null;
  phone: string | null;
  mobilePhone: string | null;
  auctionSubjectType: string;
};

export type RoomForRent = {
  roomAvailability: RoomAvailability;
  idealTenant: IdealTenant;
  roomInformations: RoomInformations;
  features: RoomForRentFeatures;
};

export type RoomForRentFeatures = {
  properties: string[];
  defaultFeatures: string[];
};

export type RoomCategory = 'complete' | 'not-complete';

export type RoomAvailability = {
  wasteTax: string;
  startDatePermanence: string;
  permanenceMonths: string;
  deposit: string;
  servicesPrice: number;
  otherExpenses: string;
  total: string;
  condominium: string;
  heating: string;
  water: string;
  expensesIncluded: string[];
};

export type IdealTenant = {
  gender: string;
  age: Nullable<string>;
  couples: string;
  smoker: string;
  animals: string;
};

export type RoomInformations = {
  id: number;
  reference: Reference | null;
  typologyRoom: string;
  categoryRoom: RoomCategory;
  tenantsNumber: number;
  tenantsGenderAndEmployment: string;
  owner: string;
  internet: boolean;
  beds: number;
  selfCatering: number;
};

export type TypologyRoom = {
  id: number;
  name: string;
  maxBeds: number;
};

export enum MultimediaTypes {
  FloorPlans = 'floorplans',
  PhotoPlan = 'photoPlan',
  Photos = 'photos',
  Videos = 'videos',
  VirtualTours = 'virtualTours',
  Map = 'map',
  Documents = 'documents',
  All = 'all',
}

export type Multimedia = {
  photos: PhotosItem[];
  videos: VideosItem[];
  floorplans: FloorplansItem[];
  photoPlan: PhotoPlanItem[];
  virtualTours: VirtualToursItem[];
  documents: DocumentsItem[];
  hasMultimedia: boolean;
  hasOnlyPhotos?: boolean;
};

export type PhotosItem = {
  id: number;
  caption: string;
  urls: Urls;
};

export interface PortionsPhotoItems extends Omit<PhotosItem, 'urls'> {
  alt: Nullable<string>;
  urls: Nullable<Urls>;
}

export type Urls = {
  thumb: string;
  small: string;
  medium: string;
  large: string;
};

export type FloorplansItem = {
  id: Nullable<number>;
  caption: Nullable<string>;
  urls: Nullable<RawUrls>;
  url: Nullable<string>;
  interactive: boolean;
};

export type PhotoPlanItem = {
  urls: RawUrls;
  url: string;
};

export type VideosItem = {
  id: number;
  url: string;
};

export type VirtualToursItem = {
  id: null;
  url: string;
};

export type DocumentFormat =
  | 'pdf'
  | 'txt'
  | 'rtf'
  | 'doc'
  | 'docx'
  | 'xls'
  | 'xlsx'
  | 'ppt'
  | 'dwg'
  | 'zip'
  | 'gzip'
  | 'gz'
  | 'jpeg'
  | 'jfif'
  | 'png'
  | 'gif'
  | 'bmp';

export type DocumentsItem = {
  title: string;
  url: string;
  type: string;
  format: DocumentFormat;
  length: number | null;
};

export type Seo = {
  canonical: string;
  alternates: Alternate[];
  title: string;
  anchor?: string;
  metaTitle: string;
  metaDescription: string;
  openGraph: OpenGraph;
  seoLinks: Nullable<SeoLink[]>; // it's possible null for units slots
  listingSearchUrl: Nullable<string>;
};

export type Alternate = {
  rel: string;
  hreflang: string;
  href: string;
};

export type OpenGraph = {
  prefix: string;
  appId: string;
  type: string;
  url: string;
  title: string;
  description: string;
  image: string;
  options: string;
  locale: string;
};

export type SeoLink = {
  type: string;
  title: string;
  url: string;
};

export type TrovaKasa = {
  nation: string;
  regionId: string;
  provinceId: string;
  cityId: number;
  geographyAreaId: number;
  zoneId: number;
  holiday: number;
  contractId: number;
  categoryId: number;
  typologyId: number;
  subTypologyId: number;
  buildingStateId: number;
  bathrooms: number;
  income: number;
  propertyTypeId: number;
  heatingId: number;
  minPrice: number;
  maxPrice: number;
  minSurface: number;
  maxSurface: number;
  minLocals: number;
  maxLocals: number;
  boxAutoId: number;
  garden: number;
  balcony: number;
  terrace: number;
  inAuction: number;
  noAuction: number;
  animals: number;
  smoker: number;
  roomsCategoryId: number;
  roomsTypologyId: number;
  energyClassId: number;
  elevator: number;
  auctionSection: number;
  furnished: string;
};

export type Socials = {
  id: number;
  url: string;
  alertType: string;
  label: string;
  cta: string;
  ctaMobile: string;
  ctaVisit?: string;
  ctaVisitMobile?: string;
  withVisit?: boolean;
};

export type MortgageCallToAction = {
  text: string;
  label: string;
  url: Nullable<string>;
};

export interface MortgageWidget {
  price?: number;
  value?: number;
  tax: number;
  callToAction: MortgageCallToAction;
  rates: MortgageRatesPerPercent[];
}

export type MortgageRatesPerPercent = {
  percent: number;
  rates: MortgageRatePerYear[];
};

export type MortgageRatePerYear = {
  year: number;
  rate: number;
};

export type MortgageBannerType =
  | 'showWidget'
  | 'link'
  | 'default'
  | 'evaluateLink'
  | 'consultingLink'
  | 'preApproveLink';

export interface MortgageBanner {
  type: MortgageBannerType;
  url?: string;
}
export interface Mortgage {
  showWidget: boolean;
  mortgageWidget: Nullable<MortgageWidget>;
  mortgageBanner: Nullable<MortgageBanner[]>;
}

export interface MortgageBannerButton {
  type?: MortgageBannerType;
  label: string;
  trackingLabel?: string;
  url?: string;
}

export type MortgageCardType = 'mortgage' | 'advertising';

export type Rent = {
  deposit: Nullable<string>;
  priceReferenceIndex: Nullable<string>;
  redemptionRent: Nullable<boolean>;
};

export type Land = {
  heightDifference: string;
  access: string;
  buildingArea: string;
  buildingHeight: string;
};
export type BuildingUsage = {
  label: string;
  value?: string;
};
export type Preview = {
  isOwner: Nullable<boolean>;
  urls: PreviewUrls;
  enabled: boolean;
};
export type PreviewUrls = {
  edit: string;
  upgrade: string | null;
};

export type AdLinks = {
  url: string;
  label: string;
  type: 'pvp' | 'external-booking-link';
};

export enum FEATURE_LIST_TYPE {
  WALL = 'wall',
  INCOME = 'income',
  ROOMS = 'rooms',
  SALE_DATE = 'saleDate',
  SURFACE = 'surface',
  BATHROOMS = 'bathrooms',
  FLOOR = 'floor',
  TYPOLOGY_AMOUNT = 'typologyAmount',
  LUXURY = 'luxury',
  ELEVATOR = 'elevator',
  BALCONY = 'balcony',
  TERRACE = 'terrace',
  FORNITURE = 'furniture',
  BASEMENT = 'basement',
  PRIVATE_OFFICE = 'private-office',
  MEETINGS = 'meetings',
  FIXED_WORKSTATION = 'fixed-workstation',
  MOBILE_WORKSTATION = 'mobile-workstation',
  WORKSTATION = 'workstation',
}

export interface Feature {
  type: FEATURE_LIST_TYPE;
  label: string;
  compactLabel?: string;
  isHighlighted?: boolean;
  forceLastPosition?: boolean;
}

export type RealEstateStatus = 'sold' | 'rent';
export interface PartnerBanner {
  image: string;
  title: string;
  body: string;
  buttons: MortgageBannerButton[];
  partnerName: string;
}
